<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-5 col-12">Event Setting</div>
    <div class="
            col-lg-7 col-12
            text-end
            d-flex
            justify-content-end
            custom-flex-cloumn-mob
          ">
    </div>
  </div>
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active" id="sms-event-setting-tab" data-bs-toggle="tab" data-bs-target="#smseventdetail"
        type="button" role="tab" aria-controls="vehicle-details" aria-selected="true">
        SMS
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link" id="email-event-setting-tab" data-bs-toggle="tab" data-bs-target="#emaileventdetail"
        type="button" role="tab" aria-controls="customer-details" aria-selected="false" @click="redirectemaileventurl()">
        Email
      </button>
    </li>
  </ul>
  <div class="tab-content mt-2" id="myTabContent">
    <div class="tab-pane fade show active" id="smseventdetail" role="tabpanel" aria-labelledby="sms-event-setting-tab">
      <div class="company-section-outer">
        <div class="layout-content-section">
          <div class="custom-ultima-datatable" style="height: calc(100vh - 188px)">
            <DataTable :value="smseventList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex"
              :paginator="true" :rows="30" responsiveLayout="scroll" :lazy="true" dataKey="ta1" :loading="loading"
              :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
              <template v-if="!loading" #empty>No records found.</template>
              <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
              <Column field="eventname" header="Event Name" headerStyle="width: 20%" bodyStyle="width: 20%">
                <template #body="{ data }">
                  <div class="text-capitalize">
                    {{ data.ta12 ? data.ta12 : "N/A" }}
                  </div>
                </template>
              </Column>
              <Column field="smstempname" header="SMS Template Name" headerStyle="width: 30%" bodyStyle="width: 30%">
                <template #body="{ data }">
                  <div class="text-capitalize">
                    {{ data.ta12 ? data.ta12 : "N/A" }}
                  </div>
                </template>
              </Column>
              <Column field="smstemplate" header="SMS Template" headerStyle="width: 38%" bodyStyle="width: 38%">
                <template #body="{ data }">
                  <div>
                    <div class="label-heading mb-2 text-capitalize" title="Header Name">
                      {{ data.ta5 ? data.ta5 : "N/A" }}
                    </div>
                    <div class="label-subheading text-capitalize" title="SMS Template">
                      {{ data.ta7 ? data.ta7 : "N/A" }}</div>
                  </div>
                </template>
              </Column>
              <Column field="smsstatus" header="SMS Status" headerStyle="width: 12%" bodyStyle="width: 12%">
                <template #body="{ data }">
                  <div>
                    <InputSwitch v-model="data.ta4" :true-value="dynamicTrueActiveValue"
                      :false-value="dynamicFalseInactiveValue" @change="changeSmsStatus(data)" />
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
    <div class="tab-pane fade" id="emaileventdetail" role="tabpanel" aria-labelledby="email-event-setting-tab">
      Email Event Setting
    </div>
  </div>
</template>
    
<script>
import ApiService from "../../service/ApiService";

export default {
  data() {
    return {
      loading: false,
      smseventList: null,
      totalRecords: 0,
      switchuncheck: false,
      dynamicTrueActiveValue: 1,
      dynamicFalseInactiveValue: 0,
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.geteventlist({ status: 0 });
  },
  methods: {
    redirectemaileventurl() {
      this.$router.push("/eventsettings/email");
    },
    geteventlist(item) {
      this.loading = true;
      this.ApiService.geteventlist(item).then((data) => {
        if (data.status == 200) {
          this.smseventList = data.data;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.smseventList = null;
          this.totalRecords = 0;
        }
      });
    },
    changePage(event) {
      this.page_no = event.page;
      this.geteventlist({ page_no: this.page_no, status: 0 });
    },
    changeSmsStatus(data) {
      let fields = {};
      fields["ta1"] = data.ta1;
      if (data.ta4 == 0 || data.ta4 == false) {
        fields["ta4"] = 0;
      }
      if (data.ta4 == 1 || data.ta4 == true) {
        fields["ta4"] = 1;
      }
      fields["status"] = 0;
      this.ApiService.updateeventstatus(fields).then((items) => {
        if (items.status === 200) {
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.geteventlist({ status: 0 });
        } else {
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },
  },
};
</script>
    
<style scoped>
.status-active {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #4daa53;
  line-height: 12px;
}

.status-inactive {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #d7a5a5;
  line-height: 12px;
}

.filter-apply-btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  font-family: "AcuminPro-Regular";
  line-height: 13px;
  height: 34px;
}

.filter-reset-btn {
  letter-spacing: 0.26px;
  color: #5266e5;
  font-size: 13px;
  font-family: "AcuminPro-Regular";
  padding: 0;
  text-decoration: none;
}

.filter-reset-btn:focus-visible,
.filter-apply-btn:focus-visible {
  outline: 0;
}

.filter-reset-btn:focus,
.filter-apply-btn:focus {
  box-shadow: none;
}

.custom-users-role {
  max-height: 250px;
  overflow-y: auto;
}

.custom-users-role::-webkit-scrollbar {
  display: block !important;
  width: 0.3em !important;
  height: 0.3em !important;
}

.custom-users-role::-webkit-scrollbar-thumb {
  background-color: rgba(31, 30, 30, 0.19) !important;
  border-radius: 3px !important;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}

.btn.user-role-btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  margin-right: 12px;
  min-width: 80px;
}

.user-role-btn:focus {
  box-shadow: none;
}
</style>
    
    